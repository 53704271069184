import { Space, Tag } from 'antd';
import { ICellRendererProps } from 'common/components/table/table.types';
import { DateToFormat, formatCustomDateTime, StrToDate, StrToDateTimeLocal } from 'common/helpers/date.helper';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import React from 'react';
import { guestTypeMap } from 'common/constans/guest-source-options.constants';
import { IGuestRead } from '../services/residents-service/residents.service.types';
import BaseCellText from 'common/components/table/base-cell-text';
import { GuestType } from '../enums/guest-type.enum';
import { formatPhone } from 'common/helpers/phone.helper';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { mapToGuestValidity } from 'common/helpers/guest-validity.helper';
import { ServerGuestValidity } from 'common/enums/guest-validity.enum';
import { GuestTypeSource } from 'common/enums/guest-type-source.enum';
import { getGuestTypeValue } from 'common/helpers/guest.helper';
import dayjs from 'dayjs';

const getValidity = (rowData: IGuestRead) => {
  const isPeriod = rowData?.endDate && rowData?.startDate && rowData.validity === ServerGuestValidity.DateRange;
  return isPeriod
    ? `${DateToFormat(rowData?.startDate, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(rowData?.endDate, DATE_SHORT_MMM_DD_YYYY)}`
    : mapToGuestValidity(rowData.validity, StrToDate(rowData?.visitDate));
};

export const guestColumns = () => ({
  lastName: {
    key: 'lastName',
    title: 'Last name',
    dataKey: 'lastName',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  firstName: {
    key: 'firstName',
    title: 'First name',
    dataKey: 'firstName',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  firstNameWithKey: {
    key: 'firstName',
    title: 'First name',
    dataKey: 'firstName',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return (
        <BaseCellText>
          {cell.rowData.issuedKeys.length ? (
            <Space className="icon-key-name-cell d-flex" size={4}>
              <KeyIcon /> {cell.rowData.firstName}
            </Space>
          ) : (
            cell.rowData.firstName
          )}
        </BaseCellText>
      );
    },
  },
  phone: {
    key: 'phone',
    title: 'Phone',
    dataKey: 'phone',
    width: 195,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return formatPhone(cell?.cellData);
    },
  },
  companyName: {
    key: 'companyName',
    title: 'Company name',
    dataKey: 'companyName',
    width: 190,
    minWidth: 190,
    resizable: true,
    sortable: true,
    fixed: 1,
  },
  type: {
    key: 'type',
    title: 'Type',
    dataKey: 'type',
    width: 152,
    minWidth: 150,
    resizable: true,
    sortable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return (
        <Tag className="eiq-tag" color={guestTypeColorMap[cell?.cellData as GuestType]} style={{ color: '#292B2E' }}>
          {getGuestTypeValue(cell?.cellData)}
        </Tag>
      );
    },
  },
  validity: {
    key: 'validity',
    title: 'Validity',
    dataKey: 'validity',
    width: 195,
    sortable: true,
    resizable: true,
    fixed: 1,
    //For sort
    getValue: getValidity,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return <BaseCellText>{getValidity(cell?.rowData)}</BaseCellText>;
    },
  },
  daysOfWeek: {
    key: 'daysOfWeek',
    title: 'Days of week for visits',
    dataKey: 'daysOfWeek',
    width: 250,
    sortable: true,
    resizable: true,
    fixed: 1,
    getValue: (rowData: IGuestRead) => rowData.daysOfWeek?.join(', '),
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      const rowData = cell?.rowData;

      return <BaseCellText>{rowData?.daysOfWeek?.length >= 7 ? `All days` : rowData?.daysOfWeek?.join(', ')}</BaseCellText>;
    },
  },
  keyPermission: {
    key: 'isKeyPermissionAllowed',
    title: 'Key permission',
    dataKey: 'isKeyPermissionAllowed',
    width: 195,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return (
        <Tag className="eiq-tag" color={keyPermissionMap[cell?.cellData + '']}>
          {cell?.cellData ? 'Allowed' : 'Not allowed'}
        </Tag>
      );
    },
  },
  dateGuestAdded: {
    key: 'createdAt',
    title: 'Date added',
    dataKey: 'createdAt',
    width: 210,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      const local = cell.cellData ? dayjs(cell.cellData, 'YYYY-MM-DD') : null;
      return formatCustomDateTime(local ? local.toDate() : null);
    },
  },
  source: {
    key: 'source',
    title: 'Source of guest',
    dataKey: 'source',
    width: 260,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      if (cell?.rowData?.source === GuestTypeSource.Resident || cell?.rowData?.source === GuestTypeSource.EIQVoice) {
        return `${guestTypeMap[cell?.rowData?.source]} (${cell.rowData.residentName})`;
      }

      if (cell?.rowData?.source === GuestTypeSource.SecurityOfficer && !!cell?.rowData?.sourceUser) {
        return `${guestTypeMap[cell?.rowData?.source]} (${cell.rowData.sourceUser.accountName})`;
      }

      return guestTypeMap[cell?.rowData?.source];
    },
  },
  restrictions: {
    key: 'restrictions',
    title: 'Restrictions',
    dataKey: 'restrictions',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  visitDate: {
    key: 'visitDate',
    title: 'Visit date',
    dataKey: 'visitDate',
    width: 210,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      const local = cell.cellData ? dayjs(cell.cellData, 'YYYY-MM-DD') : null;
      return formatCustomDateTime(local ? local.toDate() : null);
    },
  },
  isShared: {
    key: 'isShared',
    title: 'Is owner?',
    dataKey: 'isShared',
    width: 100,
    sortable: false,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      return (
        <Tag className="eiq-tag" color={cell.cellData ? '#FFB68C' : '#C1FFEA'} style={{ color: '#292B2E' }}>
          {cell.cellData ? 'No' : 'Yes'}
        </Tag>
      );
    },
  },
  carNumber: {
    key: 'carNumber',
    title: 'License plate',
    dataKey: 'carNumber',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      const primary = cell?.rowData?.carLicensePlates.find((i) => i.isPrimary);
      return primary?.number;
    },
  },
  carState: {
    key: 'carState',
    title: 'License state',
    dataKey: 'carState',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IGuestRead>) => {
      const primary = cell?.rowData?.carLicensePlates.find((i) => i.isPrimary);
      return primary?.state;
    },
  },
});

const keyPermissionMap: Record<string, string> = {
  true: '#C1FFEA',
  false: '#D1E3FF',
};

export const guestTypeColorMap: Record<GuestType, string> = {
  [GuestType.Undefined]: '#DCDCDC',
  [GuestType.Family]: '#C1FFEA',
  [GuestType.Service]: '#D1E3FF',
  [GuestType.UrgentService]: '#D1E3FF',
  [GuestType.Medical]: '#D1E3FF',
  [GuestType.RideShare]: '#FFD889',
  [GuestType.Delivery]: '#FFD889',
  [GuestType.Guest]: '#EECAFF',
  [GuestType.Employee]: '#ffd1ee',
};

const {
  lastName,
  firstNameWithKey,
  type,
  validity,
  visitDate,
  phone,
  daysOfWeek,
  keyPermission,
  restrictions,
  companyName,
  isShared,
  source,
  carNumber,
  carState,
} = guestColumns();

export const eiqSecureGuestInitialColumns = [
  lastName,
  firstNameWithKey,
  companyName,
  type,
  restrictions,
  validity,
  visitDate,
  daysOfWeek,
  keyPermission,
  isShared,
  phone,
  source,
  carNumber,
  carState,
];
