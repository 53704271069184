import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Button, Col, Form, Input, Radio, Row, Select, Space } from 'antd';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { ReactComponent as TurnbackIcon } from 'assets/icons/turnback.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { FormWrapper } from '../../../../common/components/form-wrapper/form-wrapper';
import './add-visitor.scss';
import guestService from '../../../../common/services/guest-service/guest.service';
import { notification } from '../../../../common/utils/notification';
import { formatAddressLabel } from '../../../../common/helpers/address.helper';
import { useModal } from '../../../../common/hooks/use-modal/use-modal.hook';
import { RadioChangeEvent } from 'antd/lib';
import TurnbackModal from '../turnback-modal/turnback-modal';
import ContactsModal from '../contacts-modal/contacts-modal';
import configurationStore from 'common/stores/configuration/configuration.store';
import { GuestType } from 'common/enums/guest-type.enum';
import { addVisitorValidation } from './add-visitor.validation';
import { noAddressInstructionsMessage, toGateOptions } from 'features/eiq-secure/constans/common.eiq-secure';
import SelectState from 'common/components/form-elements/select-state/select-state';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { useTableContext } from 'common/components/table/table-context';
import { TurnBack, Admit } from 'features/eiq-secure/constans/admit-actions.constants';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { AdmitGuestEnum } from '../../constans/admit-types';
import Footer from '../../../../common/components/footer/footer';
import { setResidentListSearchFilter, setVacantAddressSearchFilter } from 'common/helpers/search-filter.helper';
import { useReactToPrint } from 'react-to-print';
import EiqSecureGuestLicensePrintInstruction from '../eiq-secure-guest-license/eiq-secure-guest-license-print-instruction';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { useJsApiLoader } from '@react-google-maps/api';
import plateRecognitionContext from 'features/eiq-secure/stores/license-plate-recognition-context';
import { Image } from 'antd';
import { useDefaultAdmitValues } from 'features/eiq-secure/hooks/use-default-admit-values/use-default-admit-values.hook';

interface IAddVisitorProps {
  isVacantAddress: boolean;
}

export const AddVisitor = observer(({ isVacantAddress }: IAddVisitorProps) => {
  const tableContext = useTableContext();
  const { isMobileOrTable } = useMedia();
  const [selectedAction, setSelectedAction] = useState<string>();
  const [visitorType, setVisitorType] = useState<GuestType>();
  const [turnbackReason, setTurnbackReason] = useState<string>();
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({});

  const { defaultGate, defaultLicenseState, defaultLicensePlate, image } = useDefaultAdmitValues({
    currentGuest: null,
  });

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey });

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const gateOptions = toGateOptions(configurationStore?.configuration?.gates || []);

  const onFinish = async (values: any) => {
    const residentId = eiqSecureStore?.residentDetails?.id;
    const selectedAddressId = eiqSecureStore?.selectedAddressId;
    const selectedVacantAddressId = eiqSecureStore?.selectedVacantAddress?.id;

    const admitTurnbackVisitorBody = {
      entryType: 1,
      image: image,
      ...values,
    };

    const successMessage = selectedAction === TurnBack ? 'The visitor has been turned back' : 'The visitor has been admitted';

    if (isVacantAddress && selectedVacantAddressId) {
      await guestService.admitVisitorToVacantAddress(selectedVacantAddressId, admitTurnbackVisitorBody);
      notification.success({ message: successMessage });
      setVacantAddressSearchFilter('');
    }
    if (!isVacantAddress && residentId && selectedAddressId) {
      if (selectedAction === Admit) {
        await guestService.admitVisitor(residentId, selectedAddressId, admitTurnbackVisitorBody);
        notification.success({ message: successMessage });
      } else {
        admitTurnbackVisitorBody.reason = turnbackReason;
        await guestService.turnbackVisitor(residentId, selectedAddressId, admitTurnbackVisitorBody);
        notification.success({ message: successMessage });
      }
      setResidentListSearchFilter('');
    }
    tableContext.setSelectedRow(null);
    eiqSecureStore.setAdmitFlow(null);
    eiqSecureStore.setIsAdmitFlowProcessed();
    plateRecognitionContext.clear();
  };

  const handleClickAdmit = () => {
    setSelectedAction(Admit);
    form.submit();
  };

  const handleClickCancel = () => {
    form.resetFields();
    eiqSecureStore.setAdmitFlow(isMobileOrTable ? AdmitGuestEnum.SELECT_GUEST : null);
  };

  const handleVisitorType = (e: RadioChangeEvent) => {
    setVisitorType(e.target.value);
  };

  const contactsModal = useModal();

  const turnbackModal = useModal({
    onClose: (isSuccessSaved, turnbackReason) => {
      if (isSuccessSaved) {
        setSelectedAction(TurnBack);
        setTurnbackReason(turnbackReason);
        form.submit();
      }
    },
  });

  const handleOnValuesChange = (_: any, values: any) => {
    if (
      !values.gate ||
      !values.carLicensePlate ||
      !values.carState ||
      !values.type ||
      (isServiceGuest(values.type) && !values.companyName) ||
      (!isServiceGuest(values.type) && (!values.firstName || !values.lastName))
    ) {
      setIsButtonsDisabled(true);
      return;
    }
    setIsButtonsDisabled(false);
    setFormValues(values);
  };

  useEffect(() => {
    if (!form) {
      setIsButtonsDisabled(true);
      return;
    }
    const values = form.getFieldsValue();
    handleOnValuesChange(null, values);
  }, [form, visitorType]);

  const printRef = useRef<any>();
  const [steps, setSteps] = useState<any>();
  const promiseResolveRef = useRef<any>(null);

  useEffect(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef?.current();
    }
  }, [steps]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        if (isLoaded) {
          const selectedGates = form.getFieldValue('gate');
          const gate = configurationStore.configuration?.gates.find((i) => i.name === selectedGates);

          if (!gate?.latitude || !gate.longitude) {
            resolve(null);
          } else {
            const directionsService = new google.maps.DirectionsService();
            const origin = new google.maps.LatLng(gate.latitude, gate.longitude);
            const { city, state } = configurationStore.communitySettings!;
            const address = isVacantAddress ? eiqSecureStore.selectedVacantAddress : tableContext.getSelectedRow()?.address;

            const request: google.maps.DirectionsRequest = {
              destination: `${state}, ${city}, ${formatAddressLabel(address)}`,
              origin: origin,
              travelMode: google.maps.TravelMode.DRIVING,
            };

            directionsService.route(request, (result, status) => {
              if (status === 'OK') {
                promiseResolveRef.current = resolve;
                setSteps(result);
              } else {
                console.error(`Directions request failed due to ${status}`);
                resolve(null);
              }
            });
          }
        } else {
          resolve(null);
        }
      });
    },
  });

  const admitActionsBtn = (
    <>
      <Button type="default" icon={<PrintIcon />} size={'middle'} onClick={handlePrint} className="mobile-btn" disabled={isButtonsDisabled}>
        Print directions
      </Button>
      {!isVacantAddress && (
        <>
          <Button onClick={handleClickCancel}>Cancel</Button>
          <Button
            className="important-btn mobile-btn"
            icon={<TurnbackIcon />}
            onClick={turnbackModal.showModal}
            disabled={isButtonsDisabled}>
            Turnback
          </Button>
          <Button className="secondary-btn mobile-btn" icon={<PhoneIcon />} onClick={contactsModal.showModal}>
            Dial
          </Button>
        </>
      )}
      <Button
        type="primary"
        className="primary-btn mobile-btn"
        icon={<AdmitIcon />}
        onClick={handleClickAdmit}
        disabled={isButtonsDisabled}>
        Approve
      </Button>
    </>
  );

  const handleClickBack = () => {
    eiqSecureStore.setAdmitFlow(isMobileOrTable && !isVacantAddress ? AdmitGuestEnum.SELECT_GUEST : null);
  };

  const getGuestName = () => {
    if (isServiceGuest(formValues?.type)) {
      return formValues?.companyName;
    } else {
      return `${formValues?.firstName} ${formValues?.lastName}`;
    }
  };

  return (
    <>
      <EiqSecureGuestLicensePrintInstruction
        ref={printRef}
        address={isVacantAddress ? eiqSecureStore?.selectedVacantAddress : tableContext.getSelectedRow()?.address}
        gate={formValues?.gate}
        guestLicense={formValues.carLicensePlate}
        guestName={getGuestName()}
        rules={configurationStore.configuration?.default.communityRules}
        communityName={configurationStore.configuration?.default.communityName ?? ''}
        steps={steps}
        isMinimize={steps?.routes[0].legs[0].steps.length > 7}
      />
      <div className="gust-add-visitor column-20">
        <Row justify="space-between">
          <Col>
            <div className="space-12">
              <div className="breadcrumb-wrapper">
                {!isVacantAddress ||
                  (isVacantAddress && isMobileOrTable && (
                    <Button icon={<BackIcon />} onClick={handleClickBack}>
                      Back
                    </Button>
                  ))}
                <Breadcrumb
                  separator="–"
                  items={[
                    {
                      title: ' Guests',
                    },
                  ]}
                />
              </div>
              <span className="sub-title">
                {formatAddressLabel(isVacantAddress ? eiqSecureStore.selectedVacantAddress : eiqSecureStore.getSelectedAddress())}
              </span>
            </div>
          </Col>
          <Col>
            <Space size={8} className="buttons-font-16">
              {!isMobileOrTable && admitActionsBtn}
            </Space>
          </Col>
        </Row>
        <div className="container-row">
          <Row gutter={32}>
            <Col xs={24} lg={5} className="blue-alert">
              {(isVacantAddress ? eiqSecureStore.selectedVacantAddress?.instructions : eiqSecureStore.getSelectedAddress()?.instructions) ||
                noAddressInstructionsMessage}
            </Col>
            <Col xs={24} lg={10}>
              <FormWrapper form={form} onFinish={onFinish} layout="vertical" onValuesChange={handleOnValuesChange}>
                <Row gutter={{ lg: 32, md: 32, xs: 0 }}>
                  <Col span={24} md={8}>
                    <Form.Item name="type" label="Type" rules={addVisitorValidation.type}>
                      <Radio.Group onChange={handleVisitorType}>
                        <Space direction="vertical" size={14}>
                          <Radio value={GuestType.Guest}>Person</Radio>
                          <Radio value={GuestType.Service}>Service</Radio>
                          <Radio value={GuestType.Delivery}>Delivery</Radio>
                          <Radio value={GuestType.RideShare}>Ride share</Radio>
                          <Radio value={GuestType.UrgentService}>Urgent service</Radio>
                          <Radio value={GuestType.Medical}>Health & Medical</Radio>
                          <Radio value={GuestType.Employee}>Employee</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={16}>
                    {isServiceGuest(visitorType) && (
                      <Form.Item name="companyName" label="Company name" rules={addVisitorValidation.companyName}>
                        <Input placeholder="Enter company name" />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="firstName"
                      label="First name"
                      rules={!isServiceGuest(visitorType) ? addVisitorValidation.firstName : undefined}>
                      <Input placeholder="Enter first name" />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label="Last name"
                      rules={!isServiceGuest(visitorType) ? addVisitorValidation.lastName : undefined}>
                      <Input placeholder="Enter last name" />
                    </Form.Item>
                    <Form.Item
                      name="carLicensePlate"
                      initialValue={defaultLicensePlate}
                      label="License Plate"
                      rules={addVisitorValidation.carLicensePlate}>
                      <Input placeholder="Enter license plate" autoFocus />
                    </Form.Item>
                    <Form.Item
                      name="carState"
                      label="License state"
                      initialValue={defaultLicenseState}
                      rules={addVisitorValidation.carState}>
                      <SelectState placeholder="Select a license state" popupClassName="so-select" />
                    </Form.Item>
                    <Form.Item name="gate" label="Gate" rules={addVisitorValidation.gate} initialValue={defaultGate}>
                      <Select options={gateOptions} placeholder="Select Gate" popupClassName="so-select" />
                    </Form.Item>
                  </Col>
                </Row>
              </FormWrapper>
            </Col>
            <Col xs={24} lg={9} className="flex-center">
              {image && <Image src={image} alt="License Plate" loading="lazy" />}
            </Col>
          </Row>
        </div>
      </div>
      {isMobileOrTable && (
        <Footer>
          <Space size={8}>{admitActionsBtn}</Space>
        </Footer>
      )}
      <ContactsModal title="Contacts" isOpen={contactsModal?.isOpenModal} onClose={contactsModal?.hideModal} />
      <TurnbackModal title="Turnback" isOpen={turnbackModal?.isOpenModal} onClose={turnbackModal?.hideModal} />
    </>
  );
});
