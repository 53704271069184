import { useMemo } from 'react';
import plateRecognitionContext from 'features/eiq-secure/stores/license-plate-recognition-context';
import configurationStore from 'common/stores/configuration/configuration.store';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { IUseDefaultAdmitValuesProps } from './use-default-admit-values.types';

export const useDefaultAdmitValues = ({ currentGuest }: IUseDefaultAdmitValuesProps) => {
  const lprContext = plateRecognitionContext.data;
  const configuration = configurationStore.configuration;
  const guestLicensePlate = currentGuest?.carLicensePlates.find((i) => i.isPrimary);

  const defaultGate = useMemo(() => {
    if (lprContext) {
      return lprContext.gate;
    }
    return userAuthStore.defaultGate || undefined;
  }, [lprContext]);

  const defaultLicensePlate = useMemo(() => {
    if (guestLicensePlate?.number) {
      return guestLicensePlate.number;
    }

    if (lprContext) {
      return lprContext.licensePlate;
    }

    return undefined;
  }, [lprContext, guestLicensePlate]);

  const defaultLicenseState = useMemo(() => {
    if (guestLicensePlate?.state) {
      return guestLicensePlate.state;
    }

    if (lprContext) {
      return lprContext.licenseState.startsWith('CA-') ? 'Canada' : lprContext.licenseState;
    }

    return configuration?.default.defaultLicenseState || undefined;
  }, [lprContext, configuration, guestLicensePlate]);

  const image = useMemo(() => {
    if (!lprContext && !guestLicensePlate?.number) {
      return undefined;
    }

    return guestLicensePlate?.number ? undefined : lprContext?.image;
  }, [lprContext, guestLicensePlate]);

  return { defaultGate, defaultLicensePlate, defaultLicenseState, image: image };
};
