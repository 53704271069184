import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { eiqSecureGuestInitialColumns, guestColumns } from 'common/list-configs/guest-list.config';
import { Breadcrumb, Button, Col, Row, Space } from 'antd';
import TableBase from 'common/components/table/table-base';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { DayOfWeek, IGuestLicensePlate, IGuestRead } from 'common/services/residents-service/residents.service.types';
import { SearchFilter } from '../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { AdmitGuestEnum } from '../../constans/admit-types';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ColumnCheckFilter } from '../../../../common/components/table/filters/column-check-filter/column-check-filter';
import { guestTypeOptions } from '../../../../common/constans/guest-type-options.constant';
import { formatAddressLabel } from '../../../../common/helpers/address.helper';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import EiqSecureAddEditGuestModal from './eiq-secure-guests-add-edit-modal/eiq-secure-guests-add-edit-modal';
import { IAddEditEiqSecureGuestModal } from './eiq-secure-guests-add-edit-modal/eiq-secure-guest-add-edit-modal-types.types';
import residentsService from 'common/services/residents-service/residents.service';
import { StrToDate } from 'common/helpers/date.helper';
import ConfirmDeleteModal from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';
import { IConfirmDeleteModalProps } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import guestService from 'common/services/guest-service/guest.service';
import { getDeleteMessage } from 'features/eiq-manage/pages/eiq-manage-residents/components/info-block/guest-list/guest-list';
import KeyActionModal from '../key-action-modal/key-action-modal-modal';
import { GuestType } from 'common/enums/guest-type.enum';
import { IsServiceHoursPeriodValid } from 'common/helpers/configuration.helper';
import dayjs from 'dayjs';
import { daysOfWeek as daysOfWeekConstants } from 'common/constans/common.constants';
import { mapToGuestValidity } from 'common/helpers/guest-validity.helper';
import { GuestValidity } from 'common/enums/guest-validity.enum';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import Footer from '../../../../common/components/footer/footer';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import configurationStore from 'common/stores/configuration/configuration.store';
import './eiq-secure-guest-list.scss';
import useTableContextPersonalization from 'common/components/table/table-context-personalization';
import { ColumnFilter } from 'common/components/table/filters/column-filter/column-filter';
import { ReactComponent as FilterIcon } from 'assets/icons/filtericon.svg';

export const EIQ_SECURE_GUEST_LIST_TABLE_NAME = 'guestList';

interface IEiqSecureGuestListProps {
  handleAdmitGuest: () => void;
}
export const EiqSecureGuestList = observer(({ handleAdmitGuest }: IEiqSecureGuestListProps) => {
  const { isMobileOrTable, isMobile } = useMedia();
  const isGuestListEditableInSecure = configurationStore.configuration?.default.isGuestListEditableInSecure;

  const filterTodayGuests = (guest: IGuestRead) => {
    const today = dayjs();
    const todayDayOfWeek = today.day();
    const validDaysOfWeek = guest.daysOfWeek.includes(daysOfWeekConstants[todayDayOfWeek] as DayOfWeek);
    const validity = mapToGuestValidity(guest.validity, StrToDate(guest.visitDate));
    if (validity === GuestValidity.Today) {
      return true;
    } else if (validity === GuestValidity.Permanent && validDaysOfWeek) {
      return true;
    } else if (validity === GuestValidity.DateRange) {
      const startDate = dayjs(guest.startDate).startOf('day');
      const endDate = dayjs(guest.endDate).endOf('day');
      return today.isBetween(startDate, endDate, null, '[]');
    }
    return false;
  };

  const filterServiceGuests = (guest: IGuestRead) => {
    if (guest.type !== GuestType.Service) {
      return true;
    }
    return IsServiceHoursPeriodValid();
  };

  const data: IGuestRead[] | undefined = useMemo(() => {
    return eiqSecureStore.residentDetails?.addresses
      .find((address) => address?.address?.id === eiqSecureStore.selectedAddressId)
      ?.guests.filter((guest) => filterTodayGuests(guest) && filterServiceGuests(guest));
  }, [eiqSecureStore.selectedAddressId, eiqSecureStore.residentDetails]);

  // Table config
  const tableControls = useTableContextPersonalization(
    {
      onSelectedRow: (selectedRow: IGuestRead) => {
        eiqSecureStore.setSelectedGuest(selectedRow);
      },
      clientSide: true,
      data: data,
      initialColumns: eiqSecureGuestInitialColumns,
    },
    {
      tableName: EIQ_SECURE_GUEST_LIST_TABLE_NAME,
    },
  );

  const updateResidentDetails = () => {
    residentsService
      .getResidentDetails(eiqSecureStore.selectedResident?.id!)
      .then((data) => {
        eiqSecureStore.setResidentDetails(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleAddVisitor = () => {
    eiqSecureStore.setAdmitFlow(AdmitGuestEnum.ADD);
  };

  const addGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const { selectedAddressId, selectedResident } = eiqSecureStore;
      if (!selectedAddressId || !selectedResident?.id) {
        console.error(`SelectedAddressId or SelectedResidentId is empty`);
        return;
      }
      const initData: IAddEditEiqSecureGuestModal = {
        addressId: selectedAddressId,
        residentId: selectedResident.id,
      };
      setModalInitData(initData);
    },
    onClose: (isSuccess) => {
      if (isSuccess) updateResidentDetails();
    },
  });

  const editGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const { selectedAddressId, selectedResident } = eiqSecureStore;
      if (!selectedAddressId || !selectedResident?.id) {
        console.error(`SelectedAddressId or SelectedResidentId is empty`);
        return;
      }
      const selectedGuest = tableControls.getSelectedRow();
      const visitDate = StrToDate(selectedGuest.visitDate);

      const initData: IAddEditEiqSecureGuestModal = {
        id: selectedGuest.id,
        addressId: selectedAddressId,
        residentId: selectedResident.id,
        formInitialValues: {
          firstName: selectedGuest.firstName,
          lastName: selectedGuest.lastName,
          type: selectedGuest.type,
          validity: mapToGuestValidity(selectedGuest.validity, visitDate),
          startDate: selectedGuest.startDate ? StrToDate(selectedGuest.startDate) : null,
          endDate: selectedGuest.endDate ? StrToDate(selectedGuest.endDate) : null,
          visitDate: visitDate,
          daysOfWeek: selectedGuest.daysOfWeek,
          companyName: selectedGuest.companyName,
          carState: selectedGuest?.carLicensePlates.find((i: IGuestLicensePlate) => i.isPrimary)?.state,
          carNumber: selectedGuest?.carLicensePlates.find((i: IGuestLicensePlate) => i.isPrimary)?.number,
          phone: selectedGuest.phone,
        },
      };
      setModalInitData(initData);
    },
    onClose: (isSuccess, updatedRow) => {
      if (isSuccess) {
        updateResidentDetails();
        tableControls.updateSelectedRow(updatedRow);
      }
    },
  });

  const deleteGuestModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const { selectedAddressId, selectedResident } = eiqSecureStore;

      if (!selectedAddressId || !selectedResident?.id) {
        console.error(`SelectedAddressId or SelectedResidentId is empty`);
        return;
      }

      const guestName =
        selectedRow.firstName && selectedRow.lastName ? `${selectedRow.firstName} ${selectedRow.lastName}` : selectedRow.companyName || '';

      const data: IConfirmDeleteModalProps = {
        message: getDeleteMessage(guestName),
        onDelete: () => {
          guestService.deleteGuest(selectedResident.id, selectedAddressId, selectedRow.id).then((result) => {
            deleteGuestModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        updateResidentDetails();
        tableControls.setSelectedRow(null);
      }
    },
  });

  const collectKeyModal = useModal({
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) updateResidentDetails();
    },
  });

  const admitActionsBtn = (
    <>
      <Button icon={<KeyIcon />} onClick={collectKeyModal.showModal} disabled={!eiqSecureStore.selectedGuest?.issuedKeys.length}>
        Collect key
      </Button>
      <Button className="secondary-btn" icon={<PlusOutlined />} onClick={handleAddVisitor}>
        Add Unlisted guest
      </Button>
      <Button
        type="primary"
        className="primary-btn"
        disabled={
          !eiqSecureStore.selectedGuest?.id ||
          !(eiqSecureStore.admitFlow === null || eiqSecureStore.admitFlow === AdmitGuestEnum.SELECT_GUEST)
        }
        icon={<AdmitIcon />}
        onClick={handleAdmitGuest}>
        Next
      </Button>
    </>
  );

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <Row className="eiq-card-header eiq-secure-guest-list__card-header" justify="space-between">
          <div className="guest-address-info">
            <Col xs={24} lg={4} className="back-button">
              <div className={`space-12 ${!eiqSecureStore.admitFlow ? 'column' : ''}`}>
                <div className="breadcrumb-wrapper">
                  {eiqSecureStore.admitFlow && (
                    <Button icon={<BackIcon />} onClick={() => eiqSecureStore.setAdmitFlow(null)}>
                      Back
                    </Button>
                  )}
                  <Breadcrumb
                    separator="–"
                    items={[
                      {
                        title: ' Guests',
                        onClick: () => {},
                      },
                    ]}
                  />
                </div>
                <span className="sub-title">{formatAddressLabel(eiqSecureStore.getSelectedAddress())}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18}>
              <div className="guest-list__search-wrapper">
                <SearchFilter
                  suffix={filters}
                  adaptiveSearch={true}
                  enableProgressiveSearch={true}
                  placeholder="Search by first name, last name, company name"
                  classWrapper="guest-list__actions"
                  rulesForColumn={{
                    firstName: FilterTypesEnum.Contains,
                    lastName: FilterTypesEnum.Contains,
                    companyName: FilterTypesEnum.Contains,
                  }}
                  rightSide={
                    <Space size={12}>
                      {isGuestListEditableInSecure && (
                        <>
                          <Button
                            icon={<DeleteIcon stroke="#505762" />}
                            size="middle"
                            onClick={deleteGuestModal.showModal}
                            className="mobile-btn">
                            Delete
                          </Button>
                          <Button icon={<EditIcon />} size="middle" onClick={editGuestModal.showModal} className="mobile-btn">
                            Edit
                          </Button>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size="middle"
                            onClick={addGuestModal.showModal}
                            className="mobile-btn">
                            Add
                          </Button>
                        </>
                      )}
                    </Space>
                  }
                />
                <ColumnVisibility isColumnReorderAllow={true} />
              </div>
            </Col>
          </div>
          <Col className="guest-list__top-actions">{!isMobileOrTable && <Space size={8}> {admitActionsBtn}</Space>}</Col>
        </Row>
        <TableBase selectFirstRowByDefault minHeight={'200px'} />
        {addGuestModal.isOpenModal && (
          <EiqSecureAddEditGuestModal
            initData={addGuestModal.initData}
            title="Add"
            isEdit={false}
            isOpen={addGuestModal.isOpenModal}
            onClose={addGuestModal.hideModal}
          />
        )}
        {editGuestModal.isOpenModal && (
          <EiqSecureAddEditGuestModal
            initData={editGuestModal.initData}
            title="Edit"
            isEdit={true}
            isOpen={editGuestModal.isOpenModal}
            onClose={editGuestModal.hideModal}
          />
        )}
        <ConfirmDeleteModal
          isEdit={false}
          className={'custom-modal--so'}
          initData={deleteGuestModal?.initData}
          title="Delete"
          isOpen={deleteGuestModal?.isOpenModal}
          onClose={deleteGuestModal?.hideModal}
        />
        <KeyActionModal title="Collect" isOpen={collectKeyModal?.isOpenModal} onClose={collectKeyModal?.hideModal} />
        {isMobileOrTable && <Footer className={`button-space ${isMobile ? 'mobile-guest-list-actions' : ''}`}>{admitActionsBtn}</Footer>}
      </TableContextProvider>
    </>
  );
});

const filters = (
  <ColumnFilter
    width={258}
    buttonType="ghost"
    classNameContent="so-column-filter"
    buttonIcon={<FilterIcon />}
    buttonText={''}
    filterConfig={[
      {
        name: 'type',
        filterType: FilterTypesEnum.Equals,
        label: 'Type',
        type: {
          options: guestTypeOptions,
          type: 'SELECT',
          mode: 'multiple',
        },
      },
    ]}
  />
);
