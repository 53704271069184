import React, { forwardRef } from 'react';
import './citation-print.scss';
import { ICitationRead } from 'common/services/citation-service/citation.service.types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import configurationStore from 'common/stores/configuration/configuration.store';
import Violation from 'assets/icons/violation.png';
import { TrafficLogVisitorType } from 'common/constans/common.constants';
import { CitationStatus } from 'features/eiq-manage/enums/citation-status.enum';

interface ICitationPrint {
  citation: ICitationRead;
}

const ViolationPrint = forwardRef(({ citation }: ICitationPrint, ref: any) => {
  const communityName = configurationStore.configuration?.default.communityName;

  const getCitationCountText = () => {
    const { sequenceNumber } = citation;

    switch (sequenceNumber) {
      case 1:
        return 'first violation';
      case 2:
        return 'second violation';
      case 3:
        return 'third violation';
      default:
        return `${sequenceNumber}th violation`;
    }
  };

  const isGuest = () => {
    return citation.ownerType !== TrafficLogVisitorType.Resident;
  };

  const getGuestName = () => {
    const { guestName, companyName } = citation;

    if (guestName) {
      let result = guestName;
      if (companyName) {
        result += ` (${companyName})`;
      }
      return result;
    }
    if (companyName) {
      return companyName;
    }
    return '';
  };

  const getRegisteredTo = () => {
    if (isGuest()) {
      return getGuestName();
    }
    return citation.residentName;
  };

  const getMainText = () => {
    const { status, licensePlate, isPunishmentExtended, suspensionDays, totalSuspensionDays } = citation;

    if (status === CitationStatus.Warning) {
      if (isGuest()) {
        return `A guest associated with your property was recorded speeding in the community using vehicle tag ${licensePlate}.`;
      } else {
        return `We are writing to notify you that your vehicle with tag ${licensePlate} was recorded speeding within the community. Here are the details of the incident and the associated consequences. This is a formal warning, and further violations will result in additional penalties.`;
      }
    }

    if (isGuest()) {
      if (isPunishmentExtended) {
        return `A guest associated with your property has received a ${getCitationCountText()} in vehicle tag ${licensePlate}. Your transponder suspension has been extended by ${suspensionDays} additional days.`;
      } else {
        return `A guest associated with your property was recorded driving significantly over the speed limit in vehicle tag ${licensePlate}. As a result, your transponder has been suspended for ${suspensionDays} days effective immediately.`;
      }
    } else {
      if (isPunishmentExtended) {
        return `Your vehicle with tag ${licensePlate} has received a ${getCitationCountText()}. Due to repeated violations, your transponder suspension has been extended by ${suspensionDays} additional days.`;
      } else {
        return `We are writing to notify you that your vehicle with tag ${licensePlate} was recorded driving at a speed significantly over the community’s speed limit. Due to the nature of this violation, your transponder has been suspended for ${totalSuspensionDays} days effective immediately.`;
      }
    }
  };

  const getConsequenceText = () => {
    const { status, isPunishmentExtended, suspensionDays, totalSuspensionDays, approvedAt } = citation;

    if (status === CitationStatus.Warning) {
      return 'This is a warning. A second speeding violation will result in the suspension of your transponder for 14 days.';
    }

    if (isPunishmentExtended) {
      const dateFrom = dayjs.utc(approvedAt).local().format('MM/DD/YYYY');
      return `${suspensionDays} additional days added to transponder suspension. With your previous suspension, your transponder is suspended a total of ${totalSuspensionDays} days from ${dateFrom}.`;
    }

    return `Your transponder has been suspended for ${totalSuspensionDays} days, starting immediately.`;
  };

  return (
    <div className="citation-print">
      <div className="citation-print-body" ref={ref}>
        <div className="header">
          <div className="title">{communityName} Community</div>
          <div className="title-info">
            <div className="ticket">Ticket#: {citation.externalId}</div>
            <div className="date">Date: {dayjs(citation.occurredAt).format('MM/DD/YYYY h:mmA')}</div>
          </div>
        </div>
        <div className="info">
          <div className="column violation1">
            <div>{getMainText()}</div>
            <div className="field">
              <span>
                <span className="bold">Violation Count: </span>
                <span className="orange bold">{getCitationCountText() + (isGuest() ? ' (Guest)' : '')}</span>
              </span>
              <br />
              <span>
                <span className="bold">Consequences: </span>
                <span>{getConsequenceText()}</span>
              </span>
            </div>
          </div>
          <div className="column">
            <div className="bold">INCIDENT DETAILS</div>
            <div className="violation-field">
              License Plate:
              <span className="bold"> {citation.licensePlate}</span>
            </div>
            <div className="violation-field">
              Registered to:
              <span className="bold"> {getRegisteredTo()}</span>
            </div>
            <div className="violation-field">
              Speed Limit:
              <span className="bold green"> {citation.speedLimit} mph</span>
            </div>
            <div className="violation-field">
              Recorded Speed:
              <span className="bold orange"> {citation.violatingSpeed} mph</span>
            </div>
            <div className="violation-field">
              Date & Time:
              <span className="bold"> {dayjs(citation.occurredAt).format('MM/DD/YYYY h:mmA')}</span>
            </div>
          </div>
        </div>
        <div className="photos">
          <div className="header violation">CAPTURE EVIDENCE</div>
          <div className="images">
            {citation.photosUrl.map((item, index) => {
              return <img key={`citation-img-${index}`} src={item} alt=""></img>;
            })}
          </div>
          <div className="sub-header violation">
            <div>Location of the Violation</div>
            <div>Closeup of the License Plate</div>
          </div>
          <div className="license-images violation">
            <div className="location">
              <div className="bold blue" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>GPS Coordinates:</span>
                <span>
                  Lat: {citation.latitude} Lon: {citation.longitude}
                </span>
              </div>
              <div className="bold blue">
                <a href={`https://www.google.com/maps?q=${citation.latitude},${citation.longitude}`} target="_blank" rel="noreferrer">
                  Click or tap here to view the location of the incident and the speed camera.
                </a>
              </div>
            </div>
            <div className="img">
              <img src={citation.licensePlatePhotosUrl[0]} alt="violation img"></img>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="violation-text">
            This citation is issued by the {communityName} Homeowner’s Association (HOA) as part of our ongoing efforts to maintain safety
            and compliance within our community. Speeding poses a significant risk to residents, particularly children and pedestrians.
          </div>
          <div className="violation-text">
            We appreciate your cooperation in maintaining a safe and secure community for all residents. Should you have any questions or
            concerns, please do not hesitate to reach out with the citation <span className="bold">#{citation.externalId}</span>.
          </div>
          <div className="violation-text">
            <span className="bold">Sincerely,</span> <br />
            <span>{communityName} Homeowner’s Association</span>
          </div>
          <div className="legal-notice">
            <span className="bold">Legal Notice,</span> <br />
            This citation is issued in accordance with the rules and regulations of the {communityName} Homeowner’s Association. By residing
            in this community, you agree to abide by the HOA’s traffic policies. Failure to comply may result in additional penalties,
            including extended transponder suspension or other measures as deemed appropriate by the HOA. If you believe this citation was
            issued in error, please contact the HOA office within 5 business days of receiving this email to dispute or discuss the
            citation.
          </div>
          <div className="violation-logo">
            <img src={Violation} alt="violation img"></img>
            <span className="italic">
              This citation is from the association board via EIQ-Violation Enforcement System for which a fine and/or transponder
              suspension has been levied as provided for by the Florida statutes.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default observer(ViolationPrint);
