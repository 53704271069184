import React, { useState } from 'react';
import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useNotifications } from '../../../../common/context/NotificationsContext';
import NotificationItem from './custom-notification-item/custom-notification-item';
import './plate-scan-activity-panel.scss';

const PlateScanActivityPanel = () => {
  const { notifications, clearNotifications } = useNotifications();
  const [isPanelVisible, setIsPanelVisible] = useState(true);

  const toggleVisibility = () => {
    setIsPanelVisible((prev) => !prev);
  };

  const hasNotifications = notifications.length;

  return (
    <div className="plate-scan-activity-panel">
      {hasNotifications ? (
        <NotificationItem
          isLastNotification
          notification={notifications[0]}
          toggleVisibility={toggleVisibility}
          isPanelVisible={isPanelVisible}
        />
      ) : null}
      {(isPanelVisible || !hasNotifications) && (
        <div className="main-panel">
          <div className="panel-header">
            <h3 className="h3">PlateScan Activity</h3>
            {!hasNotifications && (
              <span className="close-btn" onClick={toggleVisibility}>
                {isPanelVisible ? <UpOutlined /> : <DownOutlined />}
              </span>
            )}
          </div>
          {isPanelVisible && (
            <>
              <div className="notifications">
                {notifications.map((item) => (
                  <NotificationItem key={item.id} notification={item} />
                ))}
              </div>
              <div className="traffic-btn">
                <Button type="link" onClick={clearNotifications}>
                  <Link to={'/eiq-secure/traffic'}>See all activity in Traffic</Link>
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PlateScanActivityPanel;
