import featureStore from 'common/stores/features/feature.store';

const isIntelliTicket = featureStore.isEIQIntelliTicketEnabled;

export enum CitationStatus {
  PayNow = 'PayNow',
  Warning = 'Warning',
  Pending = 'Pending',
  PastDue = 'PastDue',
  Delinquent = 'Delinquent',
  Canceled = 'Canceled',
  Released = 'Released',
  Paid = 'Paid',
}

export const citationStatusMap: Record<CitationStatus, string> = {
  [CitationStatus.PayNow]: 'Pay Now',
  [CitationStatus.Warning]: 'Warning',
  [CitationStatus.Pending]: 'Pending',
  [CitationStatus.PastDue]: 'Past Due',
  [CitationStatus.Delinquent]: 'Delinquent',
  [CitationStatus.Canceled]: 'Canceled',
  [CitationStatus.Released]: 'Released',
  [CitationStatus.Paid]: 'Paid',
};

export const citationStatusColorMap: Record<CitationStatus, string> = {
  [CitationStatus.PayNow]: '#C1FFEA',
  [CitationStatus.Warning]: '#FFD889',
  [CitationStatus.Pending]: '#DCDCDC',
  [CitationStatus.PastDue]: '#FFA39E',
  [CitationStatus.Delinquent]: '#FFD889',
  [CitationStatus.Canceled]: '#DCDCDC',
  [CitationStatus.Released]: '#C1FFEA',
  [CitationStatus.Paid]: '#C1FFEA',
};

export enum CitationType {
  EIQIntelliTicket = 'EIQIntelliTicket',
  EIQViolation = 'EIQViolation',
}

const citationStatusOptions = [
  { value: CitationStatus.PayNow, label: citationStatusMap[CitationStatus.PayNow] },
  { value: CitationStatus.Warning, label: citationStatusMap[CitationStatus.Warning] },
  { value: CitationStatus.Pending, label: citationStatusMap[CitationStatus.Pending] },
  { value: CitationStatus.PastDue, label: citationStatusMap[CitationStatus.PastDue] },
  { value: CitationStatus.Delinquent, label: citationStatusMap[CitationStatus.Delinquent] },
  { value: CitationStatus.Canceled, label: citationStatusMap[CitationStatus.Canceled] },
  { value: CitationStatus.Released, label: citationStatusMap[CitationStatus.Released] },
  { value: CitationStatus.Released, label: citationStatusMap[CitationStatus.Paid] },
];

export const getCitationStatusOptions = () => {
  if (!isIntelliTicket) {
    return citationStatusOptions.filter(
      (i) => i.value !== CitationStatus.PayNow && i.value !== CitationStatus.PastDue && i.value !== CitationStatus.Released,
    );
  }
  return citationStatusOptions.filter((i) => i.value !== CitationStatus.Released);
};
