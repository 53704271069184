import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Col, Form, Row, Space, Input, Switch } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import styles from '../default/default-configuration.module.scss';
import { ISystemConfigurationUpdate } from 'common/services/configuration-service/configuration.service.types';
import configurationService from 'common/services/configuration-service/configuration.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import { notification } from 'common/utils/notification';
import './citations-configurations.scss';
import featureStore from 'common/stores/features/feature.store';

const CitationConfigurations = observer(() => {
  const [form] = Form.useForm();

  const initData = configurationStore.configuration?.citations;

  let timeoutId: any;

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1500);
  };

  const onFinish = async (values: any) => {
    const model: ISystemConfigurationUpdate = {
      citations: {
        firstCitationFineAmount: Number(values.firstCitationFineAmount),
        firstCitationSpeedToleranceInPercentages: Number(values.firstCitationSpeedToleranceInPercentages),
        secondCitationFineAmount: Number(values.secondCitationFineAmount),
        thirdCitationFineAmount: Number(values.thirdCitationFineAmount),
        citationPaymentCommissionInPercentages: null,
        isPreventVendorCitations: values.isPreventVendorCitations,
        isTransponderAutoEnable: values.isTransponderAutoEnable,
      },
      serviceHours: null,
      default: null,
      guestLimitations: null,
    };
    const result = await configurationService.updateSystemConfiguration(model);
    configurationStore.setConfiguration(result);
    notification.success({
      message: 'Configurations successfully updated',
      duration: 5,
    });
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(initData);
    }
  }, [form, initData]);

  return (
    <FormWrapper form={form} onFinish={onFinish} onValuesChange={handleFormValuesChange} initialValues={initData} layout="vertical">
      <Card className="eiq-card eiq-card-border eiq-configuration">
        <Row>
          <Col xs={24} sm={12}>
            <div className={styles.configurationDefaultWrapper}>
              {featureStore.isEIQIntelliTicketEnabled && (
                <Space className="full-width">
                  <Form.Item name="firstCitationFineAmount" label="First citation fine amount ($)">
                    <Input placeholder="Enter fine amount" type="number" min={0} />
                  </Form.Item>
                </Space>
              )}
              <Space className="full-width">
                <Form.Item
                  className="first-citation-speed-tolerance"
                  name="firstCitationSpeedToleranceInPercentages"
                  label={
                    <span>
                      First citation speed limit tolerance (%)
                      <br />
                      <i>
                        This percentage decides if the first citation will be a warning or will result in a fine. If a driver exceeds the
                        speed limit over this percentage, they will be fined.
                      </i>
                    </span>
                  }>
                  <Input placeholder="Enter speed" type="number" min={0} />
                </Form.Item>
              </Space>
              {featureStore.isEIQViolationEnabled && (
                <Space className="full-width">
                  <Form.Item
                    name="isTransponderAutoEnable"
                    label="Automatically activate the transponders after their punishment period is finished"
                    valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Space>
              )}
              {featureStore.isEIQIntelliTicketEnabled && (
                <Space className="full-width">
                  <Form.Item name="secondCitationFineAmount" label="Second citation fine amount ($)">
                    <Input placeholder="Enter fine amount" type="number" min={0} />
                  </Form.Item>
                </Space>
              )}
              {featureStore.isEIQIntelliTicketEnabled && (
                <Space className="full-width">
                  <Form.Item name="thirdCitationFineAmount" label="Third citation fine amount ($)">
                    <Input placeholder="Enter fine amount" type="number" min={0} />
                  </Form.Item>
                </Space>
              )}
              <Space className="full-width">
                <Form.Item name="isPreventVendorCitations" label="Prevent sending tickets for vendor citations." valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </FormWrapper>
  );
});

export default CitationConfigurations;
