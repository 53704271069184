import { useEffect } from 'react';
import { useTableControls } from './table-context';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from './table-helpers';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import { SortBy } from './table.types';

type Personalization = {
  tableName: string;
  filterColumns?: (columns: any[]) => any[];
};

const useTableContextPersonalization = (tableControls: any, personalization: Personalization) => {
  const { tableName, filterColumns } = personalization;

  const onUpdateColumns = async (columns: any[], sortBy: any) => {
    await userPersonalizationStore.updateTableColumnsPersonalization({
      tableName: tableName,
      tableColumns: getTablePersonalizationColumnsData(columns),
      tableSort: getTablePersonalizationSortData(sortBy),
    });
  };

  const { initialColumns } = tableControls;
  const controls = useTableControls({ ...tableControls, onUpdateColumns });

  useEffect(() => {
    if (userPersonalizationStore?.isLoaded) {
      const columns = filterColumnsByPersonalization(initialColumns, tableName, filterColumns);
      const sort = personalizationDefaultTaleSort(tableName) as SortBy;
      controls.setPersonalizationData(columns, sort);
    }
  }, [userPersonalizationStore?.isLoaded]);

  return controls;
};

export default useTableContextPersonalization;
